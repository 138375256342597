module.exports = [{
      plugin: require('../node_modules/gatsby-plugin-manifest/gatsby-browser.js'),
      options: {"plugins":[],"name":"Tenxgeeks : Shaping digital product expectations into a reality","short_name":"Tenxgeeks","start_url":"/","background_color":"#5D1398","theme_color":"#5D1398","display":"minimal-ui","icon":"src/assets/images/brand-logo/tenxgeeks-logo-social.svg"},
    },{
      plugin: require('../node_modules/gatsby-plugin-google-analytics/gatsby-browser.js'),
      options: {"plugins":[],"trackingId":"UA-163865235-1"},
    },{
      plugin: require('../node_modules/gatsby-plugin-offline/gatsby-browser.js'),
      options: {"plugins":[]},
    },{
      plugin: require('../gatsby-browser.js'),
      options: {"plugins":[]},
    }]
