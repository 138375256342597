const theme = {
  colors: {
    purples: {
      500: "#5D1398"
    },
    yellows: {
      500: "#F9B21E"
    },
    reds: {
      500: "#EC4D10"
    },
    blues: {
      500: "#4392F1"
    },
    darks: {
      500: "#02182B"
    }
  },
  fontSizes: {
    1: "1.4rem",
    2: "1.8rem",
    3: "2rem",
    4: "2.4rem",
    5: "3rem",
    6: "3.8rem",
    7: "4.8rem",
    8: "6rem"
  },
  space: {},
  radii: {},
  fonts: {
    primary: `'Gilroy', sans-serif`,
    secondary: `'Spartan', sans-serif`
  },
  sizes: {},
  breakpoints: ["575.98px", "767.98px", "991.98px", "1199.98px"],
  mediaQueries: {
    // Extra small devices (portrait phones, less than 576px)
    sm: `@media (max-width: 575.98px)`,
    // Small devices (landscape phones, less than 768px)
    md: `@media (max-width: 767.98px)`,
    // Medium devices (tablets, less than 992px)
    lg: `@media (max-width: 991.98px)`,
    // Large devices (desktops, less than 1200px)
    xl: `@media (max-width: 1199.98px)`
  }
}

// aliases
theme.fontSizes.h1 = theme.fontSizes[8]
theme.fontSizes.h2 = theme.fontSizes[7]
theme.fontSizes.h3 = theme.fontSizes[6]
theme.fontSizes.h4 = theme.fontSizes[5]
theme.fontSizes.h5 = theme.fontSizes[4]
theme.fontSizes.p = theme.fontSizes[3]
theme.fontSizes.link = theme.fontSizes[2]
theme.fontSizes.base = theme.fontSizes[1]

// Button fonts
theme.fontSizes.btn = {
  sm: theme.fontSizes.base,
  md: theme.fontSizes.link,
  lg: theme.fontSizes.h4
}

// Button height
theme.sizes.btn = {
  sm: "4.8rem",
  md: "6.8rem",
  lg: "8.8rem"
}

// Button space
theme.space.btn = {
  sm: ".8rem 2.4rem",
  md: "1.2rem 3.6rem",
  lg: "1.6rem 4.8rem"
}

theme.colors.purple = theme.colors.purples[500]
theme.colors.yellow = theme.colors.yellows[500]
theme.colors.red = theme.colors.reds[500]
theme.colors.blue = theme.colors.blues[500]
theme.colors.dark = theme.colors.darks[500]
theme.colors.black = "#000"
theme.colors.white = "#fff"

theme.breakpoints.sm = theme.breakpoints[0]
theme.breakpoints.md = theme.breakpoints[1]
theme.breakpoints.lg = theme.breakpoints[2]
theme.breakpoints.xl = theme.breakpoints[3]

export default theme
